/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
           if (!$('body').hasClass('home')){
	        
				$('.wrap').waypoint(function(direction) {
					if (direction == 'down') {
						$('body').addClass('fixed-nav');
						
					}
					if (direction == 'up') {
						$('body').removeClass('fixed-nav');
						
					
					}
				}); 
        	}
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
         if ($('body').hasClass('home')){
	         
	         $('body').addClass('headhome');
			 
			var carouselHeight = $('.banner').outerHeight();
	         
			 $('.main').waypoint(function(direction) {
	          
		          if (direction == 'down') {
		          	
		          	if (!$('body').hasClass('main-menu-active')){
		          	
			          	$('body').removeClass('headhome');
			          	
			          	$('body').addClass('fixed-nav');
			          	
			          	 
			          	 
			          	$('.feature-header').css({'padding-top': carouselHeight+60+'px'});
		          	}
		          	 //menuClose();
		          }
		          else if (direction == 'up') {
			          
			         if (!$('body').hasClass('main-menu-active')){
		            $('body').addClass('headhome');
		            
		            $('body').removeClass('fixed-nav');
		            
		            $('.feature-header').css({'padding-top': ''});
		
		            }
		          }
	      	}); 
      	}
        	
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
       	if ($('body').hasClass('page-template-template-split-photo')){ 
	        $('.page-header').clone().prependTo('.main');
        }
      }
    }
  };
  
  

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

var $menuBtn = $(".hamburger");
var navbox = $('#nav-box');
var pageOffset = '0px';

function menuOpen(){
    $menuBtn.addClass("is-active");
	$('body').addClass('main-menu-active');
	
	pageOffset = window.pageYOffset;
	//console.log('menu open');
	$('body').addClass('noscroll');

}

function menuClose(){
	//var offsetStore = offset;
    $menuBtn.removeClass("is-active");
	$('body').removeClass('main-menu-active');
	$('body').removeClass('noscroll');
		console.log('offe' + pageOffset);
		console.log('close');
	$('html,body').animate({
      scrollTop: pageOffset
    }, 0);
}


function menuToggle(){
    //$menuBtn.toggleClass("is-active");
	//$('body').toggleClass('main-menu-active');
	//var pageOffset = window.pageYOffset;
	//console.log('offe' + pageOffset);
	if (!$('body').hasClass('main-menu-active')){
		menuOpen();
	}
	else if ($('body').hasClass('main-menu-active')){
		menuClose();
	}
	
}


  // Load Events
  $(document).ready(UTIL.loadEvents);

  
  $menuBtn.on("click", function(e) {
  		menuToggle();
  });
  
  //Performs a smooth page scroll to an anchor on the same page.
	var thehashref= window.location.hash;
	
	
	
	
	function hashTop(){
		if(thehashref) {
			
		setTimeout( function() {
			thehashref= window.location.hash; 
		   $('html, body').animate({
		      scrollTop: $('a[href$="'+thehashref+'"]').offset().top-80
		    }, 1000);
		    return false;
		}, 100);
		}
	}
	hashTop();
	
	 $('a[href*="#"]:not([href="#"]),a[href$="'+thehashref+'"]').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      
      var nameselect = $('[name=' + this.hash.slice(1) +']');
	      target = target.length ? target : nameselect;
	      if (target.length) {
	        
	         
		        $('html, body').animate({
		          scrollTop: target.offset().top-80
		        }, 1000);
		        return false;
	        
	        
	      }
	    }
	    hashTop();
	  });
	  
	  
	 // to top right away
	if ( thehashref ) {scroll(0,0); }
	// void some browsers issue
	setTimeout( function() { scroll(0,0); }, 1);
	
	$(".sharer").click(function(e){
    	window.open(this.href,'Share','toolbar=0,location=0,directories=0,status=0,left=auto,top=25');
		return false;
	});
	
	
	$('.featured-slider').slick({
	//autoplay: true,
		appendArrows: null,
		dots: true,
		appendDots: '.slider-pager',
		prevArrow: '.slider-prev',
		nextArrow: '.slider-next',
		fade: true,
		adaptiveHeight: true
	});
	
	
	function fallbackCopyTextToClipboard(text) {
	  var textArea = document.createElement("textarea");
	  textArea.value = text;
	  document.body.appendChild(textArea);
	  textArea.focus();
	  textArea.select();
	
	  try {
	    var successful = document.execCommand('copy');
	    var msg = successful ? 'successful' : 'unsuccessful';
	    console.log('Fallback: Copying text command was ' + msg);
	    
	    if (msg == 'successful') {
		    $('.share-link-wrap h3').html('<em style="color:#28a745;">Link Copied to Clipboard!</em>');
	    }
	    
	  } catch (err) {
	    console.error('Fallback: Oops, unable to copy', err);
	  }
	
	  document.body.removeChild(textArea);
	}
	function copyTextToClipboard(text) {
	  if (!navigator.clipboard) {
	    fallbackCopyTextToClipboard(text);
	    return;
	  }
	  navigator.clipboard.writeText(text).then(function() {
	    console.log('Async: Copying to clipboard was successful!');
	    $('.share-link-wrap h3').html('<em style="color:#28a745;">Link Copied to Clipboard!</em>');
	  }, function(err) {
	    console.error('Async: Could not copy text: ', err);
	  });
	}
	
	
	$('#fav-url').click(function() { 
	
		favVal = $(this).val();
		
		copyTextToClipboard(favVal);
	
	});
	
	function getCookie(name) {
	  var value = "; " + document.cookie;
	  var parts = value.split("; " + name + "=");
	  if (parts.length == 2) { return parts.pop().split(";").shift(); }
	}
	
	
	
	var accountMenuBtn = '<li class="menu-item menu-item-object-page menu-link-my-account-item"><a title="My Account" class="menu-link-my-account" href="/my-account/"><span class="menu-icon"></span><span class="menu-title"><span class="">My Account</span></span></a></li>';
	
	var logoutMenuBtn = '<li class="menu-item menu-item-object-page menu-link-logout-item"><a title="Logout" class="menu-link-logout" href="/wp-login.php?action=logout"><span class="menu-icon"></span><span class="menu-title"><span class="">Logout</span></span></a></li>';
	
	var favMenuBtn = '<li class="menu-item menu-item-object-page menu-link-favorites-tem"><a title="Favorites" class="menu-link-favorites" href="/favorites/"><span class="menu-icon"><span class="fav-count"></span></span><span class="menu-title"><span class="">Favorites</span></span></a></li>';
	
	 	
	
	function checkHeart(){
		
		
		var favsArr = JSON.parse(decodeURIComponent(getCookie("simplefavorites")));
		
		var favPosts =  Object.values(favsArr);
		
		
		favPosts = favPosts[0].posts;
		
		//favCount = Object.keys(favPosts).length;
		
		//safconsole.log( favCount );
		
		if ( favCount > 0 ) {
			if ( !$('body').hasClass('has-favs')){
			 	$('body').addClass('has-favs');
			 	$('.head-container').prepend('<a href="/favorites" class="favs-page-link icon-heart"><span class="fav-count">'+favCount+'</span></a>'); 
			 	$('#menu-main').append(favMenuBtn);
			 	$('.fav-count').html(favCount);
			}
			else {
				$('.fav-count').html(favCount);
			}
			
		}
		
		
	}
	setTimeout(checkHeart, 2000);
	//checkHeart();
	
	function checkLogin(){
		if ( $('body').hasClass('logged-in')){
			$('#menu-main').append(accountMenuBtn);
			$('#menu-main').append(logoutMenuBtn);
		}
	}
	checkLogin();
	
	$(document).on('favorites-updated-single', function(event, favorites, post_id, site_id, status){
		
		checkHeart();
	
	});
	
	
	$(document).on('favorites-user-favorites-loaded', function(event, favorites, intial_load){
		checkHeart();
	});
	
	
	$(document).on('favorites-nonce-generated', function(event, nonce){
		checkHeart();
	}); 

	
	$(document).on('favorites-cleared', function(event, button){
  		checkHeart();
  	});

	
	function heartAnimation(pid){
		var x = event.clientX-15;     // Get the horizontal coordinate
		var y = event.clientY-25;     // Get the vertical coordinate
		var coor = "X coords: " + x + ", Y coords: " + y;
		//console.log('adding favs'+ coor);
		
		$('<div class="heart-float icon-heart" style="top: '+ y +'px; left: '+ x +'px;" data-pid="'+ pid +'"></div>').insertBefore('.footer-main');
		
		setTimeout( function() {
			$('.heart-float').attr('data-pid', pid).css({'top':10,'left':22}).addClass('going').delay(2000).queue(function() { $(this).remove(); });
		}, 800);
		
	}
	

	
	 $('button.simplefavorite-button').on( "click", function() {
		 
		 var pid = $('data-postid');
		 
		 heartAnimation();
	 });
	
	
	var $links = $('.gallery a');
    
$links.on('click', function() {

    $.fancybox.open( $links, {
        // Custom options
    }, $links.index( this ) );

    return false;
});
	/*
	$(".fancyboxer").fancybox({
		"type": "image",
		padding: 0,
		fitToView: true,
		width: '100%',
		helpers: {
			title: null,
			overlay: {
				css: {
					'background': 'rgba(255, 255, 255, 0.85)'
				}
			}
		}
	});*/

	
	
})(jQuery); // Fully reference jQuery after this point.
